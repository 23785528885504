export const services = [
  {
    "id": 1,
    "type": 2,
    "name": "Hair Cutting",
    "description": "Experience expert hair cutting and styling at Toni & Guy, where personalized techniques enhance your look and reflect your unique style.",
    "image": "/static/haircutting.jpeg"
  },
  {
    "id": 2,
    "type": 2,
    "name": "Advance Hair Styling",
    "description": "Advanced hair styling at Toni & Guy transforms your look with personalized techniques, innovative cuts, and vibrant colors tailored to you.",
    "image": "/static/advhairstyle.jpeg"
  },
  {
    "id": 3,
    "type": 2,
    "name": "Hair Care",
    "description": "Our hair care services include nourishing treatments, deep conditioning, and scalp therapy, ensuring your hair stays healthy, vibrant, and beautiful.",
    "image": "/static/hca.jpg"
  },
  {
    "id": 26,
    "type": 2,
    "name": "Hair Botox, Hair Nanoplastia and Keratine",
    "description": "Hair treatment revitalizes and nourishes hair, addressing damage, enhancing texture, and promoting health for lustrous, manageable locks like Hair Botox, Hair Nanoplastia and Keratine.",
    "image": "/static/kre.jpg"
  },
  {
    "id": 27,
    "type": 2,
    "name": "Hair colour",
    "description": "Transform your look with vibrant hair color at Toni & Guy Women Salon, where expert stylists create stunning, personalized results just for you.",
    "image": "/static/hc.jpg"
  },
  {
    "id": 4,
    "type": 2,
    "name": "Facial & Cleanup",
    "description": "Experience rejuvenation with our facial and cleanup services, designed to cleanse, hydrate, and refresh your skin for a radiant glow.",
    "image": "/static/ttt.jpeg"
  },
  {
    "id": 5,
    "type": 2,
    "name": "Bleach & D-Tan",
    "description": "Revitalize your skin with our Bleach & D-Tan treatment, removing impurities and dark spots for a glowing, even complexion.",
    "image": "/static/bt.jpg"
  },
  {
    "id": 6,
    "type": 2,
    "name": "Manicure & Pedicure",
    "description": "Experience rejuvenation with our manicure and pedicure services, featuring nail care, exfoliation, and relaxing massage for beautifully polished hands and feet.",
    "image": "/static/manicure.jpg"
  },
  {
    "id": 7,
    "type": 2,
    "name": "Waxing",
    "description": "Experience smooth, flawless skin with our professional waxing services, designed for lasting results and comfort, leaving you feeling confident and rejuvenated.",
    "image": "/static/waxing.jpg"
  },
  {
    "id": 8,
    "type": 2,
    "name": "Body Deals",
    "description": "Experience ultimate relaxation with our body deals, featuring rejuvenating massages, nourishing scrubs, and soothing treatments designed to pamper your body.",
    "image": "/static/bodydeal.jpg"
  },
  {
    "id": 9,
    "type": 2,
    "name": "Threading",
    "description": "Threading is a precise hair removal technique using a twisted thread to remove unwanted facial hair, offering clean lines and precision.",
    "image": "/static/facialcleanup.jpeg"
  },
  
  {
    "id": 11,
    "type": 2,
    "name": "Bridal and Party Makeup",
    "description": "Our bridal makeup service creates stunning, personalized looks that enhance your natural beauty, ensuring you feel radiant on your special day.",
    "image": "/static/bridal.jpg"
  },
    {
      "id": 12,
      "type": 1,
      "name": "Haircuts and Styling",
      "description": "Experience precision haircuts and creative styling at Toni & Guy, tailored to enhance your unique look and boost your confidence.",
      "image": "/static/Hcs.jpg"
    },
    {
      "id": 15,
      "type": 1,
      "name": "Hair colour",
      "description": "Transform your look with vibrant hair dyeing services, offering customized colors and techniques to enhance your natural beauty and style.",
      "image": "/static/hd.jpg"
    },
    {
      "id": 16,
      "type": 1,
      "name": "Advanced Beard Styling",
      "description": "Advanced Beard Styling offers precision grooming techniques, including shaping, trimming, and conditioning, tailored to enhance your unique facial features and style.",
      "image": "/static/beardMen.jpg"
    },
    {
      "id": 17,
      "type": 1,
      "name": "Facial & Cleanup",
      "description": "Revitalize your skin with our facial and cleanup services, removing impurities and promoting a radiant, fresh complexion for all skin types.",
      "image": "/static/fac.jpg"
    },
    {
      "id": 18,
      "type": 1,
      "name": "Bleach & D-Tan",
      "description": "Our Bleach & D-Tan service brightens skin, removes tan, and evens out tone, leaving you with a radiant, glowing complexion.",
      "image": "/static/bbMen.jpg"
    },
    {
      "id": 19,
      "type": 1,
      "name": "Manicure and Pedicure ",
      "description": "Experience rejuvenation with our body polishing treatment for silky skin, complemented by a relaxing manicure for beautifully shaped and polished nails.",
      "image": "/static/pmm.jpg"
    },
    {
      "id": 20,
      "type": 1,
      "name": "Body Waxing",
      "description": "Experience smooth, long-lasting results with our professional body waxing services, designed for comfort and precision, leaving your skin soft and radiant.",
      "image": "/static/mwax.jpg"
    },
    
    {
      "id": 22,
      "type": 1,
      "name": "Threading",
      "description": "Threading is a precise hair removal technique using a twisted thread to gently remove unwanted facial hair, ensuring clean and defined lines.",
      "image": "/static/th.jpg"
    },
    {
      "id": 23,
      "type": 1,
      "name": "Body Polishing & Relaxation Spa",
      "description": "Relaxation spas offer serene environments for rejuvenation, featuring soothing treatments, massages, and wellness therapies to restore balance and tranquility.",
      "image": "/static/rs.jpg"
    },
    {
      "id":24,
      "type":2,
      "name":"Nail Art",
      "description":"Nail art composition may include many fashionable features and techniques, such as traditional French manicure and a special nail design.  ",
      "image":"/static/na.jpg"
    }
]
