export const data = [
    {
      "id": 1,
      "name": "Women Services",
      "description": "At Toni & Guy Dwarka, we specialize in providing an exquisite range of women's salon services designed to cater to all your beauty needs. Our expert stylists are dedicated to delivering personalized services, including haircuts, coloring, styling, and treatments, as well as professional skincare, makeup, and nail services. Whether you're looking for a fresh new look or a relaxing pampering session, our team ensures you leave feeling rejuvenated and confident.",
      "image_link": "/static/wm.jpg"
    },
    {
      "id": 2,
      "name": "Men Services",
      "description": "At Toni & Guy Dwarka, our Men Services Salon offers an unparalleled grooming experience tailored specifically for men. From precision haircuts to revitalizing facials, our expert stylists and barbers use the latest techniques and premium products to ensure you look and feel your best. Our services include beard sculpting, hot towel shaves, and relaxing head massages, all designed to provide a luxurious and personalized touch. Whether you're preparing for a special occasion or simply seeking a fresh look, our dedicated team is here to deliver exceptional service in a comfortable and stylish environment.",
      "image_link": "/static/sm.jpg"
    },
  ];
  